<template>
  <div class="StaffManagement">
    <a-button-group style="margin-top:10px;">
      <a-button
        :class="index == 1 ? 'ant-btn-primary' : ''"
        @click="routerBtn(1)"
        >员工管理</a-button
      >
      <a-button
        :class="index == 2 ? 'ant-btn-primary' : ''"
        @click="routerBtn(2)"
        >离职管理</a-button
      >
      <a-button
        :class="index == 3 ? 'ant-btn-primary' : ''"
        @click="routerBtn(3)"
        >员工合同管理</a-button
      >
    </a-button-group>
    <div
      :style="{
        'border-top': '1px solid #E0E1E2',
        'margin-top': '-1px',
        'min-height': clientH
      }"
    >
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
  name: 'StaffManagement',
  data () {
    return {
      index: 1,
      clientH: '500px',
      urlArr: [
        '/staffManagement/SatffManagement',
        '/staffManagement/QuitManagement',
        '/staffManagement/interviewManagement'
      ]
    }
  },
  created () {
    if (this.$route.path == '/staffManagement/QuitManagement') {
      this.index = 2
    } else if (this.$route.path == '/staffManagement/SatffManagement') {
      this.index = 1
    } else {
      this.index = 3
    }
  },
  mounted () {
    let domHeight = document.documentElement.clientHeight
    this.clientH = domHeight - 227 + 'px'
  },
  methods: {
    routerBtn (i) {
      this.index = i
      let that = this
      that.$router.push({
        //核心语句
        path: that.urlArr[i - 1] //跳
      })
    }
  }
}
</script>
<style lang="less">
.StaffManagement {
  position: relative;
  background: #fff;
  border-radius: 4px;

  overflow-y: auto;
  user-select: none;
  font-size: 14px;
  color: #666666;
  padding: 15px;
}
</style>
